// Global styling for this template
body {
  min-height: 100vh;
  position: relative;
  margin: 0;
  padding-bottom: 115px; //height of the footer
  box-sizing: border-box;
}
html {
  height: 100%;
}

p {
  font-size: 1.1rem;
    line-height: 1.5;
    margin: 30px 0;

    a {
        text-decoration: underline;
    }
}

h1,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    text-transform: uppercase;
}
h3 {
  font-size: 2.5rem;
}

h2 {
    font-weight: 600;
    line-height: 1;
    font-family: d-din-probold;
    font-size: 3.5rem;
}

h1 {
  font-family: d-din-proextrabold;
  line-height: 1;
}

.highlight {
  font-weight: 1000;
  font-size: 6.5rem;
  max-width: 100%;
}
@media (max-width: 700px) {
  .highlight {
    font-size: calc(3rem + 3vw) !important;
  }
}

a {
    color: $gray-900;

    &:focus,
    &:hover {
        color: $primary;
        text-decoration: none;
    }
}

blockquote {
    font-style: italic;
    color: $gray-600;
}

.section-heading {
    font-size: 36px;
    font-weight: 700;
    margin-top: 60px;
}

.caption {
    font-size: 14px;
    font-style: italic;
    display: block;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

::-moz-selection {
    color: $white;
    background: $primary;
    text-shadow: none;
}

::selection {
    color: $white;
    background: $primary;
    text-shadow: none;
}

img::selection {
    color: $white;
    background: transparent;
}

img::-moz-selection {
    color: $white;
    background: transparent;
}

// Styling for the masthead
header.masthead {
    // TIP: Background images are set within the HTML using inline CSS!
    background: no-repeat center center;
    background-color: $white;
    background-attachment: scroll;
    position: relative;
    background-size: cover;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $white;
        opacity: 0.5;
    }

    .page-heading,
    .site-heading {
      padding: 100px 0 0;
    }

    .post-heading {
      padding: 50px 0 0;
    }

    .page-heading-simple {
      padding: 67px 0 0;
      color: white;

      @media only screen and (min-width: 768px) {
        padding: 67px 0 0;
      }

    }

    .page-heading,
    .site-heading {
        text-align: center;

        h1 {
            font-size: 50px;
            margin-top: 0;
        }

        .subheading {
            font-size: 24px;
            font-weight: 300;
            line-height: 1.1;
            display: block;
            margin: 10px 0 0;
            font-family: $headings-font-family;
        }

        @media only screen and (min-width: 768px) {
            h1 {
                font-size: 80px;
            }
        }
    }

    .page-heading-simple {

        h1 {
            font-size: 50px;
            margin-top: 0;
        }

        .subheading {
            font-size: 24px;
            font-weight: 300;
            line-height: 1.1;
            display: block;
            margin: 10px 0 0;
            font-family: $headings-font-family;
        }

        @media only screen and (min-width: 768px) {
            h1 {
                font-size: 50px;
            }
        }
    }

    .post-heading {
      img {
        max-width: 100%;
        min-width: 50%;
      }
        h1 {
            font-size: 35px;
        }

        .meta,
        .subheading {
            line-height: 1.1;
            display: block;
        }

        .subheading {
            font-size: 24px;
            font-weight: 600;
            margin: 30px 0 30px;
            font-family: $headings-font-family;
        }

        .meta {
            font-size: 20px;
            font-weight: 300;
            font-style: italic;
            font-family: $font-family-base;

            a {
                color: $white;
            }
        }

        @media only screen and (min-width: 768px) {
            h1 {
                font-size:85px;
            }

            .subheading {
                font-size: 30px;
            }
        }
    }
}

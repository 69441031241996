// Styling for the footer
footer {
   // padding: 50px 0 65px;
   height: 115px;
   position: absolute;
   bottom: 0;
   width: 100%;
    .list-inline {
        margin: 0;
        padding: 0;
    }

    .copyright {
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
    }
    .footerLogo {
      height: 50px;
    }
}
